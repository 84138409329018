
import { Options, Vue } from 'vue-class-component'
import checkoutSuccess from '@/components/Frontend/Checkout/CheckoutSuccess/index.vue'

@Options({
  components: {
    checkoutSuccess
  }
})

export default class CheckOutSuccess extends Vue {}
