import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class Orders extends APIBaseFunctions {
  static OrdersDataService = class {
    private static cachedOrdersDataDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Orders.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedOrdersDataDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/ordres?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/ordres?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`ordres/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/ordres/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/ordres', data)
    }

    static update (id: string, data: any) {
      return http.put(`/ordres/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/ordres/${id}`)
    }

    static deleteAll () {
      return http.delete('/ordres')
    }

    static findBySearchTerm (searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Orders.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedOrdersDataDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (!isNaN(Number(searchTerm))) {
        // Handle number searches in either order invoices or order transaction
        retVal = http.get(`/ordres?_where[_or][0][ordre_fakturanummer_contains]=${searchTerm}&_where[_or][1][ordre_transaktion_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else if (searchTerm.indexOf('@') >= 0) {
        // Handle email address in search term
        retVal = http.get(`/ordres?ordre_email_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/ordres?ordre_navn_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return retVal
    }

    static findBySelectedSearchTerm (searchFields: any, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Orders.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedOrdersDataDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }
      let queryParameters = ''
      for (let i = 0; i < searchFields.length; i++) {
        if (searchFields[i].value === 'ordreenheders_id') {
          queryParameters += `&_where[_or][${i}][ordreenheders_id.ordreenheder_beskrivelse_contains]=${searchTerm}`
        } else {
          queryParameters += `&_where[_or][${i}][${searchFields[i].value}_contains]=${searchTerm}`
        }
      }

      const retVal = http.get(`/ordres?${queryParameters}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))

      return retVal
    }
  }

  static OrderUnitsDataService = class {
    private static cachedOrderUnitsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Orders.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedOrderUnitsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/ordreenheders?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/ordreenheders?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/ordreenheders/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/ordreenheders/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/ordreenheders', data)
    }

    static update (id: string, data: any) {
      return http.put(`/ordreenheders/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/ordreenheders/${id}`)
    }

    static deleteAll () {
      return http.delete('/ordreenheders')
    }

    static findBySearchTerm (searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Orders.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedOrderUnitsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/ordreenheders?produkt_id.produkt_navn_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }
  }

  static ClubOrderDataService = class {
    private static cachedClubOrderDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Orders.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedClubOrderDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/ordre-klubs?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/ordre-klubs?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/ordre-klubs/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/ordre-klubs/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/ordre-klubs', data)
    }

    static update (id: string, data: any) {
      return http.put(`/ordre-klubs/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/ordre-klubs/${id}`)
    }

    static deleteAll () {
      return http.delete('/ordre-klubs')
    }

    static findBySearchTerm (searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Orders.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedClubOrderDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }
      if (!isNaN(Number(searchTerm))) {
        // Handle number searches in eitehr order invoices or order transaction
        retVal = http.get(`/ordre-klubs?_where[_or][0][ordre_id.ordre_fakturanummer_contains]=${searchTerm}&_where[_or][1][ordre_id.ordre_transaktion_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/ordre-klubs?ordre_id.ordre_navn_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return retVal
    }
  }
}

export default new Orders()
